<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :loaded-options="options"
            :total-elements="totalElements"
            :total-pages="totalPages"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            addButtonTitle="+ Создать маршрутный лист"
            @onChangeData="onChangeData"
            @handleActions="handle"
        ></crm-table-pageable-new>
      </v-col>
    </v-row>
    <route-sheet v-if="routeSheetOpened"
                 :visible="routeSheetOpened"
                 :item="item"
                 @close="closeDialog"/>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import RouteSheet from './RouteSheet.vue';

export default {
  components: {
    RouteSheet,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      routeSheetOpened: false,
      item: null,
      headers: [
        {
          value: 'routeSheetNumber', sortable: false, text: '№', type: 'defaultItem',
        },
        {
          value: 'created', sortable: true, text: 'Дата и время создания', type: 'defaultItem',
        },
        {
          value: 'createdByName', sortable: false, text: 'Автор создания', type: 'defaultItem',
        },
        {
          value: 'courierName', sortable: false, text: 'Курьер', type: 'defaultItem',
        },
        {
          value: 'planDeliveryDate', sortable: false, text: 'Дата доставки', type: 'date',
        },
        {
          value: 'deliveryTime', sortable: false, text: 'Время доставки', type: 'defaultItem',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'enums', enums: 'routeSheetEnums',
        },
        {
          value: 'totalOrder', sortable: false, text: 'Количество заказов', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          align: 'center',
          child: [
            {
              label: 'Просмотр',
              event: 'view',
              icon: 'mdi-eye',
              availableConditionName: 'canView',
            },
            {
              label: 'Печать',
              event: 'print',
              icon: 'mdi-printer',
              availableConditionName: 'printable',
            },
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              availableConditionName: 'editable',
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              availableConditionName: 'deletable',
            },
          ],
        },
      ],
      data: [],
      config: {
        add: true,
        search: true,
        pageable: true,
      },
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'createdDateRange', name: 'Дата', type: 'dateRange', menu: false, active: false,
        },
      ],
      totalElements: 0,
      totalPages: 0,
      url: '/route-sheets',
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
      },
    };
  },
  async created() {
    await this.getRouteSheetStatuses();
    this.onChangeData(this.$route.query);
  },
  methods: {
    getRouteSheetStatuses() {
      return this.$routeSheets.get('/route-sheet/status').then((response) => {
        if (response && response.content) {
          response.content.forEach((i) => {
            this.availableFilters.push({
              id: i.code,
              name: i.nameRu,
              type: 'status',
              active: false,
            });
          });
        }
      });
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getDelivery();
    },
    getDelivery() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$routeSheets.get(formattedUrl, {
        params: {
          PageNumber: params.page,
          PageSize: params.size,
          Sorting: params.sort,
          SearchText: params.search,
          Status: params.statuses,
          DeliveryDateStart: params.createdDateRange && params.createdDateRange.split(',')[0],
          DeliveryDateFinish: params.createdDateRange && params.createdDateRange.split(',')[1],
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = response.recordsFiltered > response.pageSize
          ? Math.ceil(response.recordsFiltered / response.pageSize)
          : 1;
        this.data = response.content.map((i) => ({
          ...i,
          created: this.$moment(i.created).format('DD.MM.YYYY HH:mm'),
          deliveryTime: `${i.deliveryTimeStart}-${i.deliveryTimeFinish}`,
          canView: i.status !== 'NEW',
          editable: i.status === 'NEW',
          deletable: i.status === 'NEW',
          printable: i.status !== 'DELETED',
        }));
        this.data = [...this.data];
      }).finally(() => {
        this.$loading(false);
      });
    },
    closeDialog() {
      this.routeSheetOpened = false;
      this.item = null;
      this.onChangeData(this.$route.query);
    },
    handle(element) {
      switch (element.type) {
        case 'create':
          this.routeSheetOpened = true;
          break;
        case 'view':
          this.editRouteSheet(element);
          break;
        case 'print':
          this.printRouteSheet(element);
          break;
        case 'edit':
          this.editRouteSheet(element);
          break;
        case 'delete':
          this.deleteRouteSheet(element);
          break;
        default:
          break;
      }
    },
    printRouteSheet(element) {
      this.$loading(true);
      this.$routeSheets.get(`/route-sheets/${element.item.id}/print`, {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Маршрутный лист №${element.item.routeSheetNumber}`;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(link.href);
      }).finally(() => this.$loading(false));
    },
    showRouteSheet(element) {
      this.routeSheetOpened = true;
      this.item = element.item;
    },
    editRouteSheet(element) {
      this.routeSheetOpened = true;
      this.item = element.item;
    },
    deleteRouteSheet(element) {
      element.event.stopPropagation();
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        this.$loading(true);
        this.$routeSheets.delete(`${this.url}/${element.item.id}`)
          .then(() => {
            this.onChangeData(this.$route.query);
          }).finally(() => this.$loading(false));
      }).catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
