<template>
  <v-dialog v-model="visible" width="1800"
            content-class="route-sheet"
            @click:outside="closeDialog"
            @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">
          Маршрутный лист {{ itemLocal.routeSheetNumber ? `#${itemLocal.routeSheetNumber}` : ''}}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="route-sheet__content">
        <div class="route-sheet__content--info">
          <v-form ref="routeSheet" v-model="valid">
            <v-row>
              <v-col>
                <v-select
                    persistent-placeholder=""
                    label="ФИО Курьера"
                    :clearable="isRouteSheetNew"
                    prepend-icon="mdi-truck-fast"
                    v-model="itemLocal.courierId"
                    :items="couriers"
                    :rules="[(v) => !!v || 'Обязательно поле']"
                    item-text="label"
                    item-value="value"
                    :readonly="!isRouteSheetNew"
                    :disabled="loading"
                />
              </v-col>
              <v-col>
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        persistent-placeholder
                        v-model="itemLocal.planDeliveryDate"
                        label="Дата доставки"
                        prepend-icon="mdi-calendar"
                        :readonly="!isRouteSheetNew"
                        :clearable="isRouteSheetNew"
                        :rules="[(v) => !!v || 'Обязательно поле']"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="loading"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="itemLocal.planDeliveryDate"
                      :min="today"
                      :readonly="!isRouteSheetNew"
                      @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="d-flex">
                <v-select
                    persistent-placeholder=""
                    label="Время доставки"
                    :clearable="isRouteSheetNew"
                    prepend-icon="mdi-clock-outline"
                    prefix="с"
                    v-model="itemLocal.deliveryTimeStart"
                    @change="setDeliveryTimeStart($event)"
                    :items="timeIntervals"
                    :rules="[(v) => !!v || 'Обязательно поле']"
                    item-text="label"
                    item-value="value"
                    :disabled="loading"
                    :readonly="!isRouteSheetNew"
                />
                <v-select
                    v-model="itemLocal.deliveryTimeFinish"
                    :items="timeIntervals.filter((t, index) => index > deliveryTimeIndex)"
                    :clearable="isRouteSheetNew"
                    prefix="по"
                    item-text="label"
                    item-value="value"
                    :disabled="loading || !itemLocal.deliveryTimeStart"
                    :readonly="!isRouteSheetNew"
                    :rules="[(v) => !!v || 'Обязательно поле']"
                />
              </v-col>
            </v-row>
            <v-row v-if="isRouteSheetNew">
              <v-col cols="11">
                <v-autocomplete v-model="selectedOrders"
                                label="Заказы"
                                persistent-placeholder=""
                                multiple
                                @update:search-input="getOrders"
                                return-object
                                :item-text="item => `№${item.id} ${item.addressDelivery}`"
                                item-value="id"
                                prepend-icon="mdi-order-bool-descending"
                                small-chips
                                clearable
                                :disabled="loading"
                                deletable-chips
                                :items="orders"/>
              </v-col>
              <v-col cols="1">
                <v-btn fab
                       small
                       color="#007450"
                       @click="addOrders">
                  <v-icon color="#ffffff">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div class="route-sheet__content--orders">
          <v-row v-if="isRouteSheetNew">
            <hint-text class="ml-5 mt-3">
                <span>
                  Необходимо добавить <b>минимум один заказ</b> в маршрутный лист.
                </span>
            </hint-text>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <crm-table-pageable-new :config="config"
                                      :toolbarShown="false"
                                      :headers="headers"
                                      @handleActions="handle"
                                      tableWrapperMinHeight="calc(100vh - 400px)"
                                      :data="routeSheetOrders" />
            </v-col>
          </v-row>
          <v-row v-if="showOrderChangeForm">
            <v-col>
              <v-form
                  ref="changeOrderForm"
                  v-model="validChangeOrder"
                  class="mx-4"
                  lazy-validation
              >
                <h3 class="mb-2">Переназначение курьера №{{ currentOrder.id }} заказа</h3>
                <v-autocomplete
                    v-model="selectedRouteSheet"
                    return-object
                    :items="routeSheets"
                    :loading="loading"
                    :disabled="loading"
                    placeholder="Введите номер маршрутного листа для поиска"
                    :search-input.sync="searchOrderText"
                    clearable
                    :rules="[(v) => !!v || 'Обязательное поле']"
                    item-value="id"
                    item-text="name"
                    prepend-icon="mdi-order-bool-descending"
                    label="Маршрутные листы"/>
                <v-text-field label="Причина переназначения"
                              prepend-icon="mdi-comment"
                              clearable
                              class="mb-2"
                              :rules="[(v) => !!v || 'Обязательное поле']"
                              v-model="changeReason" />
                <v-btn rounded
                       color="primary"
                       :disabled="loading"
                       @click="changeOrder">
                  <span class="px-2">Переназначить</span>
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions class="justify-start pb-5">
        <v-btn text rounded @click="closeDialog">
          Отменить
        </v-btn>
        <div v-if="isRouteSheetNew">
          <v-btn rounded
                 v-if="item && item.id"
                 color="primary"
                 :disabled="loading"
                 @click="updateRouteSheet">
            <span class="px-2">Сохранить</span>
          </v-btn>
          <v-btn rounded
                 v-else
                 :disabled="loading"
                 color="primary"
                 @click="createRouteSheet">
            <span class="px-2">Создать</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import lodash from 'lodash';
import HintText from '@/components/HintText.vue';

export default {
  name: 'RouteSheet',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
    HintText,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({
        id: 0,
        courierId: '',
        planDeliveryDate: null,
        deliveryTimeStart: '',
        deliveryTimeFinish: '',
      }),
    },
  },
  data() {
    return {
      menu: false,
      valid: false,
      validChangeOrder: false,
      loading: false,
      timerId: null,
      today: new Date().toJSON(),
      selectedOrders: [],
      couriers: [],
      orders: [],
      headers: [
        {
          value: 'id', sortable: false, text: '№ Заказа', type: 'defaultItem',
        },
        {
          value: 'addressDelivery', sortable: false, text: 'Адрес доставки', type: 'defaultItem',
        },
        {
          value: 'contactName', sortable: false, text: 'Контакное лицо', type: 'defaultItem',
        },
        {
          value: 'telephone', sortable: false, text: 'Номер телефона', type: 'defaultItem',
        },
        {
          value: 'comment', sortable: false, text: 'Комментарий', type: 'defaultItem',
        },
        {
          value: 'orderAmount', sortable: false, text: 'Сумма заказа', type: 'defaultItem',
        },
        {
          value: 'paymentType', sortable: false, text: 'Способ оплаты', type: 'enums',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'enums', enums: 'orderStatusEnums',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          align: 'center',
          child: [
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              availableConditionName: 'deletable',
            },
            {
              label: 'Переназначить',
              event: 'change',
              icon: 'mdi-account-convert',
              availableConditionName: 'changeable',
            },
          ],
        },
      ],
      routeSheetOrders: [],
      timeIntervals: [
        {
          label: '09:00',
          value: '09-00',
        },
        {
          label: '09:30',
          value: '09-30',
        },
        {
          label: '10:00',
          value: '10-00',
        },
        {
          label: '10:30',
          value: '10-30',
        },
        {
          label: '11:00',
          value: '11-00',
        },
        {
          label: '11:30',
          value: '11-30',
        },
        {
          label: '12:00',
          value: '12-00',
        },
        {
          label: '12:30',
          value: '12-30',
        },
        {
          label: '13:00',
          value: '13-00',
        },
        {
          label: '13:30',
          value: '13-30',
        },
        {
          label: '14:00',
          value: '14-00',
        },
        {
          label: '14:30',
          value: '14-30',
        },
        {
          label: '15:00',
          value: '15-00',
        },
        {
          label: '15:30',
          value: '15-30',
        },
        {
          label: '16:00',
          value: '16-00',
        },
        {
          label: '16:30',
          value: '16-30',
        },
        {
          label: '17:00',
          value: '17-00',
        },
        {
          label: '17:30',
          value: '17-30',
        },
        {
          label: '18:00',
          value: '18-00',
        },
        {
          label: '18:30',
          value: '18-30',
        },
        {
          label: '19:00',
          value: '19-00',
        },
        {
          label: '19:30',
          value: '19-30',
        },
        {
          label: '20:00',
          value: '20-00',
        },
        {
          label: '20:30',
          value: '20-30',
        },
        {
          label: '21:00',
          value: '21-00',
        },
      ],
      config: {
        pageable: false,
      },
      deliveryTimeIndex: null,
      showOrderChangeForm: false,
      currentOrder: null,
      searchOrderText: null,
      selectedRouteSheet: null,
      changeReason: null,
      routeSheets: [],
    };
  },
  watch: {
    searchOrderText(val) {
      if (!val) return;
      if (this.selectedRouteSheet && this.selectedRouteSheet.name === val) {
        return;
      }
      this.getRouteSheets(val);
    },
  },
  computed: {
    itemLocal() {
      if (this.item) {
        return {
          ...this.item,
          planDeliveryDate: this.item.planDeliveryDate.substring(0, 10),
        };
      }
      return {
        id: 0,
        courierId: '',
        planDeliveryDate: null,
        deliveryTimeStart: '',
        deliveryTimeFinish: '',
        status: 'NEW',
      };
    },
    isRouteSheetNew() {
      return this.itemLocal.status === 'NEW';
    },
  },
  mounted() {
    if (this.itemLocal.id) {
      this.getRouteSheetsOrders();
    }
    this.getCouriers();
  },
  methods: {
    setDeliveryTimeStart(event) {
      this.deliveryTimeIndex = this.timeIntervals.map((t) => t.value).indexOf(event);
    },
    addOrders() {
      this.routeSheetOrders = [
        ...this.routeSheetOrders,
        ...this.selectedOrders.map((i) => ({
          ...i,
          deletable: this.isRouteSheetNew,
        })),
      ];
      this.orders = lodash.differenceBy(this.orders, this.selectedOrders, 'id');
      this.selectedOrders = [];
    },
    getRouteSheetsOrders() {
      this.$routeSheets.get(`/route-sheet/${this.itemLocal.id}/orders`)
        .then((response) => {
          this.routeSheetOrders = response.content.map((i) => ({
            ...i,
            deletable: this.isRouteSheetNew,
            changeable: i.status === 'ON_DELIVERY' && !this.isRouteSheetNew,
          }));
        });
    },
    getCouriers() {
      this.$routeSheets.get('/courier').then((response) => {
        this.couriers = response.content.map((i) => ({
          label: `${i.firstName} ${i.lastName}`,
          value: i.id,
        }));
      });
    },
    getOrders(searchText) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.$routeSheets.get('/order', { params: { SearchText: searchText } }).then((response) => {
          this.orders = response.content;
        });
      }, 1000);
    },
    getRouteSheets(searchText) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.loading = true;
        this.$routeSheets.get('/route-sheets', {
          params: {
            Status: 'NEW',
            SearchText: searchText,
          },
        }).then((response) => {
          this.routeSheets = [
            ...this.routeSheets,
            ...response.content.map((i) => ({
              ...i,
              name: `№${i.routeSheetNumber} (${i.courierName})`,
            })),
          ];
        }).finally(() => { this.loading = false; });
      }, 1000);
    },
    closeDialog() {
      this.$emit('close');
    },
    createRouteSheet() {
      if (this.routeSheetOrders.length === 0) {
        this.$toast.warning('Заказы не добавлены в маршрутный лист');
      }
      if (this.$refs.routeSheet.validate()) {
        this.loading = true;
        this.$routeSheets.post('/route-sheets', {
          courierId: this.itemLocal.courierId,
          planDeliveryDate: this.itemLocal.planDeliveryDate,
          deliveryTimeStart: this.itemLocal.deliveryTimeStart,
          deliveryTimeFinish: this.itemLocal.deliveryTimeFinish,
          routeSheetOrders: this.routeSheetOrders.map((o) => ({ orderId: o.id })),
        }).then(() => {
          this.closeDialog();
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.$toast.warning('Заполните все поля');
      }
    },
    updateRouteSheet() {
      if (this.routeSheetOrders.length === 0) {
        this.$toast.warning('Заказы не добавлены в маршрутный лист');
      }
      if (this.$refs.routeSheet.validate()) {
        this.loading = true;
        this.$routeSheets.put('/route-sheets', {
          id: this.itemLocal.id,
          routeSheetNumber: this.itemLocal.routeSheetNumber,
          courierId: this.itemLocal.courierId,
          planDeliveryDate: this.itemLocal.planDeliveryDate,
          deliveryTimeStart: this.itemLocal.deliveryTimeStart,
          deliveryTimeFinish: this.itemLocal.deliveryTimeFinish,
          routeSheetOrders: this.routeSheetOrders.map((o) => ({ id: o.id })),
        }).then(() => {
          this.closeDialog();
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.$toast.warning('Заполните все поля');
      }
    },
    handle(element) {
      switch (element.type) {
        case 'delete':
          this.deleteItem(element);
          break;
        case 'change':
          this.selectOrder(element);
          break;
        default:
          break;
      }
    },
    selectOrder(element) {
      this.currentOrder = element.item;
      this.showOrderChangeForm = true;
    },
    changeOrder() {
      if (!this.currentOrder) {
        this.$store.dispatch('tools/setSnackbar', {
          type: 'error',
          message: 'Заказ не выбран!',
        });
      }
      if (this.$refs.changeOrderForm.validate()) {
        this.$routeSheets.post('/route-sheet/change/order', {
          routeSheetId: this.item.id,
          orderId: this.currentOrder.id,
          newRouteSheetId: this.selectedRouteSheet.id,
          reasonForChange: this.changeReason,
        }).then(() => {
          this.getRouteSheetsOrders();
          this.showOrderChangeForm = false;
          this.currentOrder = null;
          this.selectedRouteSheet = null;
          this.changeReason = null;
        });
      } else {
        this.$store.dispatch('tools/setSnackbar', {
          type: 'error',
          message: 'Заполните поля',
        });
      }
    },
    deleteItem(element) {
      element.event.stopPropagation();
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        this.orders.push(element.item);
        this.routeSheetOrders = this.routeSheetOrders
          .filter((o) => o.id !== element.item.id);
      }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.route-sheet {
  .v-card__actions {
    border-top: 1px solid #EFEFEF;
  }
 &__content {
   padding: 20px!important;
   &--info {
     padding: 20px;
     border: 1px solid #EFEFEF;
     box-sizing: border-box;
     border-radius: 12px;
     margin-bottom: 12px;
   }
   &--orders {
     padding: 12px;
     border: 1px solid #EFEFEF;
     box-sizing: border-box;
     border-radius: 12px;
   }
 }
}
</style>
