<template>
  <div class="hint">
    <v-icon class="hint__icon">{{ icon }}</v-icon>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'HintText',
  props: {
    icon: {
      type: String,
      default: 'mdi-information-outline',
    },
  },
};
</script>
<style lang="scss">
.hint {
  display: flex;
  padding: 10px;
  color: rgba(0, 0, 0, 0.5);

  &__icon {
    margin-right: 10px;
  }
}
</style>
